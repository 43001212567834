import React, { FC } from 'react';

import { IArticleCardDescription } from '@shared/types/umbraco/compositions';
import { DangerouslySetInnerHtml } from 'layout';

const RelatedArticleCardDescription: FC<IArticleCardDescription> = ({ title }) => (
  <DangerouslySetInnerHtml element="h3" className="article-card__title" html={title} />
);

export default RelatedArticleCardDescription;
