import React, { FC, useEffect, useRef } from 'react';

import connectHits from 'react-instantsearch-core/dist/es/connectors/connectHits';
import { IQuizCarousel } from 'components/Quiz/models';
import RelatedArticleCard from 'components/RelatedArticleCard';
import ProductCard from 'components/ProductCard';
import BlockCarousel from 'components/BlockCarousel';
import PrevArrow from 'components/PDCarousel/components/prevArrow';
import NextArrow from 'components/PDCarousel/components/nextArrow';
import { hasLongWords } from 'utils/stringUtils/stringUtils';
import './QuizCarousel.scss';

const QuizCarousel: FC<IQuizCarousel> = ({
  hits,
  limit = 4,
  waiSettings,
  ariaLoading,
  usePriceSpider,
  isEanProductId,
}) => {
  const ref = useRef(null);
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    customPaging: () => <span className="btn btn--icon" />,
    prevArrow: <PrevArrow ariaLabel={waiSettings?.ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={waiSettings?.ariaNext} />,
    responsive: [
      {
        breakpoint: 1980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (hits?.length) {
      ref.current?.querySelectorAll('[href]')[0]?.focus({
        preventScroll: true,
      });
    }
  }, [hits?.length]);

  return (
    <div className="quiz-carousel" data-testid="quiz-carousel" ref={ref}>
      {hits?.length > 0 ? (
        <BlockCarousel settings={settings}>
          {hits.slice(0, limit).map((carouselItem) => {
            if (carouselItem.objectID?.startsWith('Product')) {
              return (
                <ProductCard
                  {...carouselItem}
                  key={
                    carouselItem.cardLink?.length ? carouselItem.cardLink[0].url : carouselItem.link
                  }
                  usePriceSpider={usePriceSpider}
                  isTitleLong={hasLongWords(carouselItem?.title)}
                  isEanProductId={isEanProductId}
                />
              );
            }

            return <RelatedArticleCard {...carouselItem} key={carouselItem.link} />;
          })}
        </BlockCarousel>
      ) : (
        <div className="quiz-carousel__loading btn--loading">
          <span className="sr-only">{ariaLoading}</span>
        </div>
      )}
    </div>
  );
};

export default connectHits(QuizCarousel);
