import { useProduct } from 'context/ProductContextProvider/ProductContextProvider';
import React from 'react';
import './BestsellerCard.scss';
import { Link } from 'gatsby';

import { Image, MoneyV2, Scalars } from 'utils/storefront-api-types';

function calculateDiscountPercentage(priceV2Amount: string, compareAtPriceAmount: string) {
  return 100 - Math.ceil((parseInt(priceV2Amount, 10) / parseInt(compareAtPriceAmount, 10)) * 100);
}

const BestsellerCard = () => {
  const { product: data } = useProduct();
  const isProductVariant = data.__typename === 'ProductVariant';
  const isProduct = data.__typename === 'Product';

  const { onlineStoreUrl, variants, title, images } = isProductVariant ? data.product : data;

  let compareAtPriceAmount: MoneyV2['amount'] = '0.00';
  let priceV2Amount: MoneyV2['amount'] = '0.00';

  if (isProduct && variants.length > 0) {
    compareAtPriceAmount = variants[0].compareAtPrice?.amount;
    priceV2Amount = variants[0].priceV2?.amount;
  } else if (isProductVariant) {
    compareAtPriceAmount = data?.compareAtPrice?.amount;
    priceV2Amount = data?.priceV2?.amount;
  }

  const objectId: Scalars['ID'] = data.id.replace(/^.*\//, '');

  const compareAtPrice: Partial<MoneyV2> = {
    amount: '0.00',
  };
  let discount: MoneyV2['amount'];

  if (compareAtPriceAmount !== priceV2Amount) {
    discount = `${calculateDiscountPercentage(priceV2Amount, compareAtPriceAmount)}`;
    compareAtPrice.amount = compareAtPriceAmount;
  }

  const image: Partial<Image> = {
    url: images[0]?.url,
    altText: images[0]?.altText,
  };

  return (
    <div className="bestseller-product-card bestseller-product-card--bundle">
      <span className="bestseller-product-card__line" />
      <div className="bestseller-product-card__text">
        <a
          href={isProduct ? onlineStoreUrl : `${onlineStoreUrl}?variant=${objectId}`}
          className="bestseller-product-card__link"
        >
          <h3 className="bestseller-product-card__title">{title}</h3>
        </a>
        {discount && <div className="bestseller-product-card__label">{`${discount}% off`}</div>}
        {isProductVariant && <p className="bestseller-product-card__variant">{data.title}</p>}
      </div>
      <div className="bestseller-product-card__stock-info">
        <div className="stock-info__price">
          {compareAtPrice.amount !== '0.00' && (
            <div className="stock-info__price--old">
              <span className="sr-only">Was</span>
              {` £${compareAtPrice.amount}`}
            </div>
          )}
          {priceV2Amount && (
            <div className="stock-info__price--new">
              <span className="sr-only">Now</span>
              {` £${priceV2Amount}`}
            </div>
          )}
        </div>
      </div>
      <div className="bestseller-product-card__buy-button">
        <a
          className="btn btn--secondary-white add-to-cart__button"
          href={`https://shop.finish.co.uk/cart/add/${objectId}`}
        >
          <span className="icon icon__prefix icon--cart" />
          <span className="btn__inner">ADD TO CART</span>
        </a>
      </div>
      <div className="bestseller-product-card__image">
        <div className="bestseller-product-card__image-img">
          <Link to={isProduct ? onlineStoreUrl : `${onlineStoreUrl}?variant=${objectId}`}>
            {image.url && <img src={image.url} loading="lazy" alt={image.altText} />}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BestsellerCard;
