import React, { FC, useEffect, useRef, useState, memo } from 'react';

import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import classNames from 'classnames';
import { IQuizSlide } from 'components/Quiz/models';
import QuizOption from '../QuizOption';
import './QuizSlide.scss';

const QuizSlide: FC<IQuizSlide> = ({
  quizState: {
    currentSlide: {
      properties: { question, options },
    },
    focusTrapIsActive,
  },
  handleSelectOption,
}) => {
  const ref = useRef(null);
  const name = `${question?.slice(4, 10)}${question?.length}`.replace(/ /g, '');
  const [value, setValue] = useState('');
  useEffect(() => {
    if (focusTrapIsActive) {
      ref.current?.firstElementChild.focus({
        preventScroll: true,
      });
    }
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyPress = (event, tags, nextSlide) => {
    event.preventDefault();
    if (event.charCode === 32 || event.charCode === 13) {
      handleSelectOption({
        tags: tags?.map((tag) => tag.title),
        nextSlideUrl: nextSlide?.[0]?.url,
      });
    }
  };

  const handleClick = (event, tags, nextSlide) => {
    setValue(event.target.value);
    handleSelectOption({
      tags: tags?.map((tag) => tag.title),
      nextSlideUrl: nextSlide?.[0]?.url,
    });
  };

  return (
    <fieldset className="quiz-slide" data-testid="quiz-slide" aria-labelledby={name}>
      <div className="quiz-slide__text">
        {question ? (
          <DangerouslySetInnerHtml
            element="legend"
            id={name}
            className="quiz-slide__description"
            html={question}
          />
        ) : null}
      </div>
      <div
        className={classNames('quiz-slide__options', {
          [`quiz-slide__options--${options?.length}-plus`]: options?.length,
        })}
        ref={ref}
      >
        {options?.length > 0
          ? options.map((option) => (
              <QuizOption
                key={option.properties.title}
                {...{
                  ...option?.properties,
                  name,
                  value,
                  handleChange,
                  handleKeyPress,
                  handleClick,
                }}
              />
            ))
          : null}
      </div>
    </fieldset>
  );
};

export default memo(QuizSlide);
