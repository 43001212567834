import { DeepPartial } from './models';

export function flattenConnection<T>(obj?: DeepPartial<T>) {
  if (Array.isArray(obj)) {
    return obj.map(flattenConnection);
  }
  if (typeof obj === 'object' && obj !== null) {
    if ('nodes' in obj || 'edges' in obj || 'node' in obj) {
      if ('nodes' in obj && Array.isArray(obj.nodes)) {
        return obj.nodes.map((node) => flattenConnection(node));
      }
      if ('edges' in obj && Array.isArray(obj.edges)) {
        return obj.edges.map((edge) => flattenConnection(edge.node));
      }
      if ('node' in obj) {
        return flattenConnection(obj.node);
      }
    } else {
      const result = {};
      Object.keys(obj).forEach((key) => {
        result[key] = flattenConnection(obj[key]);
      });

      return result;
    }
  }

  return obj;
}
