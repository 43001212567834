import React, { createContext, useContext, useMemo } from 'react';
import { flattenConnection } from 'utils/flatten-connection';
import { ProductContextTypes } from './model';

const ProductOptionsContext = createContext<{ product: ProductContextTypes.TFlatLineItem }>(null);

export function useProduct() {
  const context = useContext(ProductOptionsContext);

  if (!context) {
    throw new Error(`'useProduct' must be a child of <ProductProvider />`);
  }

  return context;
}

export function ProductProvider({
  children,
  data: productData,
}: {
  children: React.ReactChild;
  data: ProductContextTypes.ILineItemsResponse;
}) {
  if (!productData || productData.errors) {
    console.error('There was an error while fetching product data');

    return null;
  }

  const product = useMemo<ProductContextTypes.TFlatLineItem>(
    () => flattenConnection(productData ?? {}),
    [productData]
  );

  const value = useMemo(() => ({ product }), [product]);

  return <ProductOptionsContext.Provider value={value}>{children}</ProductOptionsContext.Provider>;
}
