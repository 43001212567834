import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { UmbracoProductListingBannerCarousel } from '@shared/types/umbraco/compositions';
import Button from 'common/Button';
import Section from 'common/Section';
import ProductCard from 'components/ProductCard';
import useShopifyHelper from 'hooks/useShopifyHelper';
import BlockCarousel from 'components/BlockCarousel';
import PrevArrow from 'components/PDCarousel/components/prevArrow';
import NextArrow from 'components/PDCarousel/components/nextArrow';

import './ProductListingBannerCarousel.scss';

const ProductListingBannerCarousel: FC<UmbracoProductListingBannerCarousel.IData> = ({
  intro,
  links,
  carouselProducts,
  color,
  lang,
  isShopify,
  isEanProductId,
  ariaPrev,
  ariaNext,
}) => {
  const { mergeCmsShopifyProducts } = useShopifyHelper({ isShopify, lang });
  const productsList = mergeCmsShopifyProducts(carouselProducts);

  const settings = {
    customPaging: () => <span className="btn btn--icon" />,
    prevArrow: (
      <PrevArrow ariaLabel={ariaPrev} customIcon="chevron-left-colored" iconColorCount={2} />
    ),
    nextArrow: (
      <NextArrow ariaLabel={ariaNext} customIcon="chevron-right-colored" iconColorCount={2} />
    ),
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: '0',
    centerMode: true,
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          centerMode: false,
          infinite: false,
          slidesToShow: 2,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          centerMode: true,
          slidesToShow: 1,
          centerPadding: '80px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          centerMode: true,
          slidesToShow: 1,
          centerPadding: '60px',
        },
      },
      {
        breakpoint: 414,
        settings: {
          dots: true,
          centerMode: true,
          slidesToShow: 1,
          centerPadding: '40px',
        },
      },
    ],
  };

  return (
    <Section
      classes="product-listing-banner-carousel"
      name="product-listing-banner-carousel"
      color={color}
      intro={intro}
    >
      <div
        className={classnames('product-listing-banner--carousel-theme', {
          'product-listing-banner--default': productsList.length === 3,
        })}
      >
        <div
          className="product-listing-banner-carousel__grid"
          data-testid="product-listing-banner-carousel"
        >
          <BlockCarousel settings={settings}>
            {productsList?.length
              ? productsList.map((product) => (
                  <div key={product.link}>
                    <ProductCard
                      {...product}
                      isShopify={isShopify}
                      lang={lang}
                      isEanProductId={isEanProductId}
                    />
                  </div>
                ))
              : null}
          </BlockCarousel>
        </div>
        {links?.length ? (
          <div className="product-listing-banner-carousel__links">
            {links.map(({ properties: { link, ariaLabel } }, idx) => (
              <Button
                to={link[0].url}
                ariaLabel={ariaLabel}
                key={link[0].name}
                variant={idx > 0 ? 'secondary-link' : 'primary-link'}
                iconSuffix="chevron-right"
              >
                {link[0].name}
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </Section>
  );
};

export const query = graphql`
  fragment FragmentProductListingWithCarousel on TProductListingWithCarousel {
    structure
    properties {
      ariaPrev
      ariaNext
      intro
      color {
        label
        value
      }
      links {
        structure
        properties {
          ariaLabel
          link {
            name
            url
          }
        }
      }
      products {
        name
        url
      }
    }
  }
`;

export default ProductListingBannerCarousel;
