import React, { FC } from 'react';
import classNames from 'classnames';
import ExternalLink from 'common/ExternalLink';
import GatsbyImage from 'common/GatsbyImage';

import { UmbracoRelatedArticles } from '@shared/types/umbraco/compositions';
import getCategory from 'utils/getCategory';
import getLabel from 'utils/getLabel';
import RelatedArticleCardDescription from './RelatedArticleCardDescription/RelatedArticleCardDescription';

import './RelatedArticleCard.scss';

export interface IRelatedArticleCard extends UmbracoRelatedArticles.IArticleCard {
  isFull?: boolean;
}

const RelatedArticleCard: FC<IRelatedArticleCard> = ({
  link,
  tags,
  title,
  image,
  isFull = false,
}) => {
  const category = getCategory(tags);
  const label = getLabel(tags);

  return (
    <ExternalLink
      link={link}
      className={classNames('article-card', {
        [`article-card--full`]: isFull,
      })}
      data-testid="article-card"
    >
      {image?.length ? (
        <div
          className={classNames('article-card__image', {
            [`article-card__image--full`]: isFull,
          })}
        >
          <GatsbyImage
            image={image[0].properties.image}
            className="article-card__image-img"
            alt={image[0].properties.imageAlt}
            useFocalPoint
          />
          {label ? (
            <div
              className={classNames('article-card__label', {
                [`article-card__label--full`]: isFull,
              })}
            >
              {label}
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={classNames('article-card__description', {
          [`article-card__description--full`]: isFull,
        })}
      >
        {category ? <div className="article-card__category">{category}</div> : null}
        {title ? <RelatedArticleCardDescription title={title} /> : null}
      </div>
    </ExternalLink>
  );
};

export default RelatedArticleCard;
