import React, { FC } from 'react';
import { IQuizResult } from 'components/Quiz/models';
import algoliasearch from 'algoliasearch/lite';
import { Configure } from 'react-instantsearch-dom';
import InstantSearch from 'react-instantsearch-core/dist/es/widgets/InstantSearch';
import Button from 'common/Button';
import { DangerouslySetInnerHtml } from 'layout';
import QuizCarousel from '../QuizCarousel';
import './QuizResult.scss';

// eslint-disable-next-line
const GATSBY_ALGOLIA_SEARCH_QUERY_INDEX = process.env.GATSBY_ALGOLIA_SEARCH_QUERY_INDEX;

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
);

const QuizResult: FC<IQuizResult> = ({
  resetQuiz,
  quizState: { collectedData },
  waiSettings,
  quizResult: [
    {
      properties: { restart, limit, ariaRestart, resultDescription, ariaLoading },
    },
  ],
  lang,
  usePriceSpider,
  isEanProductId,
}) => (
  <div className="quiz-result" data-testid="quiz-result">
    {resultDescription ? (
      <DangerouslySetInnerHtml className="quiz-result__description" html={resultDescription} />
    ) : null}
    <InstantSearch
      indexName={GATSBY_ALGOLIA_SEARCH_QUERY_INDEX}
      searchClient={searchClient}
      refresh
      stalledSearchDelay={500}
    >
      <Configure
        analytics={false}
        distinct
        tagFilters={collectedData}
        hitsPerPage={200}
        filters={`lang:${lang}`}
      />
      <QuizCarousel
        limit={limit}
        waiSettings={waiSettings}
        ariaLoading={ariaLoading}
        usePriceSpider={usePriceSpider}
        isEanProductId={isEanProductId}
      />
    </InstantSearch>
    <div className="quiz-result__reset">
      <Button ariaLabel={ariaRestart} type="reset" variant="secondary" clickHandler={resetQuiz}>
        {restart}
      </Button>
    </div>
  </div>
);

export default QuizResult;
