import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import { IQuizOptionContent } from 'components/Quiz/models';
import IconCustom from 'common/IconCustom';

const QuizOptionContent: FC<IQuizOptionContent> = ({ title, description, icon }) => (
  <>
    {icon?.length ? <IconCustom icon={icon[0]} /> : null}
    <DangerouslySetInnerHtml className="quiz-option-label__title" html={title} />
    <DangerouslySetInnerHtml className="quiz-option-label__description" html={description} />
  </>
);

export default QuizOptionContent;
