import React, { FC, useRef, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { Container } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IQuizPlaceholderFront } from '@shared/types/frontend/compositions';
import QuizSlide from './components/QuizSlide';
import useQuiz from './useQuiz';
import QuizResult from './components/QuizResult';
import QuizSteps from './components/QuizSteps';

import './Quiz.scss';
import './QuizOverride.scss';

const Quiz: FC<IQuizPlaceholderFront> = ({
  quizData,
  renderQuiz,
  title,
  waiSettings,
  lang,
  usePriceSpider,
  isEanProductId,
  isThreeColumnsStyle,
}) => {
  const ref = useRef(null);
  const { isMobile } = useScreenRecognition();

  const { relatedSlides, quizResult } = quizData;

  const {
    quizState,
    quizState: {
      currentSlide: { url },
      isDone,
      currentStep,
      focusTrapIsActive,
    },
    steps,
    handleSelectOption,
    resetQuiz,
  } = useQuiz(relatedSlides);

  useEffect(() => {
    if (isMobile && focusTrapIsActive) {
      ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [isMobile, focusTrapIsActive, currentStep]);

  return renderQuiz ? (
    <div
      className={classNames('quiz-section', 'quiz-section--dark-theme', {
        'quiz-section--three-columns': isThreeColumnsStyle,
      })}
      data-testid="quiz"
      ref={ref}
    >
      <Container fluid>
        <form action="#" className="quiz">
          <h2 id="progressbar-label" className="sr-only">
            {title}
          </h2>
          <div className="quiz-slide__header">
            <QuizSteps steps={steps} currentStep={currentStep} />
          </div>
          <TransitionGroup>
            <CSSTransition key={currentStep} timeout={900} classNames="slide">
              {url && !isDone ? (
                <QuizSlide quizState={quizState} handleSelectOption={handleSelectOption} />
              ) : (
                <QuizResult
                  quizState={quizState}
                  resetQuiz={resetQuiz}
                  quizResult={quizResult}
                  waiSettings={waiSettings}
                  lang={lang}
                  usePriceSpider={usePriceSpider}
                  isEanProductId={isEanProductId}
                />
              )}
            </CSSTransition>
          </TransitionGroup>
        </form>
      </Container>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentQuiz on TQuizPlaceholder {
    properties {
      renderQuiz
      title
      isThreeColumnsStyle
    }
    structure
  }
`;

export default Quiz;
