import { UmbracoTags } from '@shared/types/umbraco/tags';
import { parseBoolean } from './parseBoolean';

const getCategory = (tags: UmbracoTags.IBaseTag[]): string | null => {
  if (!tags?.length) return null;
  const categoryTAg = tags.filter((tag) => (parseBoolean(tag.isCategory) ? tag.title : null));

  return categoryTAg?.length ? categoryTAg[0].title : null;
};

export default getCategory;
