import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IExternalLink } from './models';

const ExternalLink: FC<IExternalLink> = ({
  link = '',
  children,
  target = '_blank',
  ariaLabel,
  ...rest
}) =>
  link.startsWith('/') ? (
    <Link to={link} {...rest} aria-label={ariaLabel}>
      {children}
    </Link>
  ) : (
    <a href={link} rel="noopener nofollow" aria-label={ariaLabel} {...{ target, ...rest }}>
      {children}
    </a>
  );

export default ExternalLink;
