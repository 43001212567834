import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IQuizOption } from 'components/Quiz/models';
import QuizOptionContent from 'components/Quiz/components/QuizOptionContent';

import './QuizOption.scss';

const QuizOption: FC<IQuizOption> = ({
  title,
  description,
  icon,
  nextSlide,
  tags,
  name,
  handleChange,
  handleKeyPress,
  value,
  handleClick,
  isDirectLink,
}) => (
  <>
    {isDirectLink ? (
      <Link to={nextSlide[0].url} aria-label={title} className="quiz-option-label btn btn--icon">
        <QuizOptionContent icon={icon} title={title} description={description} />
      </Link>
    ) : (
      <>
        <input
          className="sr-only quiz-option__input"
          type="radio"
          id={title}
          name={name}
          value={title}
          checked={value === title}
          onChange={handleChange}
          onKeyPress={(e) => handleKeyPress(e, tags, nextSlide)}
        />
        <label
          className="quiz-option-label btn btn--icon"
          data-testid="quiz-option-label"
          htmlFor={title}
          onMouseDown={(e) => handleClick(e, tags, nextSlide)}
        >
          <QuizOptionContent icon={icon} title={title} description={description} />
        </label>
      </>
    )}
  </>
);

export default QuizOption;
