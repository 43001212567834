import { useState, useMemo } from 'react';
import { TSlide } from '../../../../@shared-types/frontend/compositions/quizPlaceholder.d';
import { TState } from './models';

const useQuiz = (
  slides: TSlide[]
): {
  steps: number[];
  handleSelectOption: (props: { tags: string[]; nextSlideUrl: string }) => void;
  quizState: TState;
  resetQuiz: () => void;
} => {
  const steps = useMemo(() => {
    const uniqSteps = [...new Set(slides.map((slide) => slide.properties.step))].sort();
    const resultStep = uniqSteps[uniqSteps.length - 1] + 1;

    return [...uniqSteps, resultStep];
  }, [slides.length]);

  const firstSlide = useMemo(
    () => slides.find((slide) => slide.properties.step === 1),
    [slides.length]
  );

  const [quizState, setQuizState] = useState({
    currentStep: 1,
    currentSlide: firstSlide,
    collectedData: [],
    isDone: false,
    focusTrapIsActive: false,
  });

  const handleSelectOption = ({ tags, nextSlideUrl }) => {
    if (nextSlideUrl) {
      const nextSlide = slides.find((slide) => slide.url === nextSlideUrl);
      setQuizState((prevState) => {
        const data = [...prevState.collectedData, tags];

        return {
          ...prevState,
          currentStep: nextSlide?.properties.step,
          currentSlide: nextSlide,
          collectedData: data,
          focusTrapIsActive: true,
        };
      });
    } else {
      setQuizState((prevState) => {
        const data = [...prevState.collectedData, tags];

        return {
          ...prevState,
          currentStep: prevState.currentStep + 1,
          collectedData: data,
          isDone: true,
          focusTrapIsActive: true,
        };
      });
    }
  };

  const resetQuiz = () => {
    setQuizState({
      currentStep: 1,
      currentSlide: firstSlide,
      collectedData: [],
      isDone: false,
      focusTrapIsActive: true,
    });
  };

  return {
    steps,
    handleSelectOption,
    quizState,
    resetQuiz,
  };
};

export default useQuiz;
