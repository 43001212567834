import classNames from 'classnames';
import BestsellerCard from 'components/BestsellerCard';
import { ProductProvider } from 'context/ProductContextProvider/ProductContextProvider';
import React, { useEffect, useState } from 'react';
import Client from 'shopify-buy/index.unoptimized.umd';

import './BestsellersList.scss';
import { Link, graphql } from 'gatsby';
import { ProductContextTypes } from 'context/ProductContextProvider/model';
import { Product, ProductVariant } from 'utils/storefront-api-types';
import { BestsellersListProps } from './model';

const BestsellersList: React.FC<BestsellersListProps> = ({
  buttonLabel,
  bestsellerCards,
  buttonLink,
}) => {
  const { GATSBY_STOREFRONT_ACCESS_TOKEN } = process.env;
  const { GATSBY_SHOPIFY_URL } = process.env;

  const client = Client.buildClient({
    domain: GATSBY_SHOPIFY_URL,
    storefrontAccessToken: GATSBY_STOREFRONT_ACCESS_TOKEN,
    language: 'en',
  });

  const [queryResponse, setQueryResponse] = useState<ProductContextTypes.ILineItemsResponse>(null);

  const ids = bestsellerCards.map((card) => card?.properties?.productId);

  const query = client.graphQLClient.query((root) => {
    root.add(
      'nodes',
      {
        args: {
          ids,
        },
      },
      (nodes) => {
        nodes.addInlineFragmentOn('Product', (product) => {
          product.add('id');
          product.add('title');
          product.add('onlineStoreUrl');
          product.addConnection('images', { args: { first: 1 } }, (image) => {
            image.add('url');
          });
          product.addConnection('variants', { args: { first: 10 } }, (variantNode) => {
            variantNode.add('id');
            variantNode.add('sku');
            variantNode.add('compareAtPrice', (priceV2) => {
              priceV2.add('amount');
            });
            variantNode.add('priceV2', (priceV2) => {
              priceV2.add('amount');
            });
            variantNode.add('quantityAvailable');
          });
        });
        nodes.addInlineFragmentOn('ProductVariant', (variant) => {
          variant.add('id');
          variant.add('title');
          variant.add('product', (product) => {
            product.add('id');
            product.add('title');
            product.add('onlineStoreUrl');
            product.addConnection('images', { args: { first: 1 } }, (image) => {
              image.add('url');
            });
          });
          variant.add('sku');
          variant.add('compareAtPrice', (priceV2) => {
            priceV2.add('amount');
          });
          variant.add('priceV2', (priceV2) => {
            priceV2.add('amount');
          });
          variant.add('quantityAvailable');
        });
      }
    );
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productInfo = await client.graphQLClient.send(query);
        setQueryResponse(productInfo);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  if (!queryResponse || !queryResponse?.data) {
    return null;
  }

  return (
    <>
      <ul className={classNames('bestsellers-collection', 'bestsellers-collection--bundles')}>
        {queryResponse.data.nodes.map((response: Product | ProductVariant | null) =>
          response.id ? (
            <li className="visible" key={response.id}>
              <ProductProvider data={response}>
                <BestsellerCard />
              </ProductProvider>
            </li>
          ) : null
        )}
      </ul>
      <div className="bestsellers-collection-shop-all">
        {buttonLink[0]?.properties?.link[0]?.name && (
          <Link className="btn btn--secondary-blue" to={buttonLink[0]?.properties?.link[0]?.name}>
            {buttonLabel}
          </Link>
        )}
      </div>
    </>
  );
};

export default BestsellersList;

export const query = graphql`
  fragment FragmentBestsellersList on TBestsellersList {
    properties {
      buttonLink {
        properties {
          ariaLabel
          link {
            name
            target
          }
        }
      }
      buttonLabel
      bestsellerCards {
        properties {
          productId
        }
      }
    }
    structure
  }
`;
