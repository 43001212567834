import React, { FC } from 'react';
import classNames from 'classnames';
import { IQuizSteps } from 'components/Quiz/models';
import './QuizSteps.scss';

const QuizSteps: FC<IQuizSteps> = ({ steps, currentStep }) => (
  <div
    className="quiz-steps"
    data-testid="quiz-steps"
    role="progressbar"
    aria-valuenow={(((currentStep - 1) / (steps.length - 1)) * 100).toFixed()}
    aria-valuemin={0}
    aria-valuemax={steps.length}
    aria-labelledby="progressbar-label"
  >
    {steps.map((step) => (
      <span
        aria-hidden="true"
        className={classNames('quiz-steps__item', {
          'quiz-steps__item--active': step === currentStep,
        })}
        key={step}
      >
        {step}
      </span>
    ))}
  </div>
);

export default QuizSteps;
