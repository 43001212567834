import React, { FC } from 'react';

import { graphql } from 'gatsby';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IThanksgivingBannerProps } from './model';

import './ThanksgivingBanner.scss';

const ThanksgivingBanner: FC<IThanksgivingBannerProps> = ({
  title,
  cta,
  description,
  image,
  icon,
  background,
  mobileBackground,
}) => {
  const { isDesktop } = useScreenRecognition();

  const backgroundImage = isDesktop
    ? background?.[0]?.properties
    : mobileBackground?.[0]?.properties;

  return (
    <section className="thanksgiving-banner">
      <div className="thanksgiving-banner__container">
        <div className="thanksgiving-banner__content">
          <DangerouslySetInnerHtml html={title} className="thanksgiving-banner__title" />
          <DangerouslySetInnerHtml
            html={description}
            className="thanksgiving-banner__description"
          />

          <div className="thanksgiving-banner__row">
            {cta?.map(({ properties: { link, ariaLabel = '' } }) => {
              const { name, url, target } = link?.[0] || {};

              return (
                <Button to={url} ariaLabel={ariaLabel} variant="primary" target={target}>
                  {name}
                </Button>
              );
            })}

            {isDesktop ? (
              <div className="thanksgiving-banner__icon">
                <GatsbyImage
                  image={icon[0].properties.image}
                  alt={icon[0].properties.imageAlt}
                  objectFit="contain"
                  className="thanksgiving-banner__icon-image"
                  isLazyLoading={false}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="thanksgiving-banner__image">
          <GatsbyImage
            image={image[0].properties.image}
            alt={image[0].properties.imageAlt}
            objectFit="contain"
            isLazyLoading={false}
          />
        </div>
      </div>

      {backgroundImage ? (
        <div className="thanksgiving-banner__background">
          <GatsbyImage
            image={backgroundImage.image}
            alt={backgroundImage.imageAlt}
            isLazyLoading={false}
          />
        </div>
      ) : null}
    </section>
  );
};

export const query = graphql`
  fragment FragmentThanksgivingBanner on TThanksgivingBanner {
    properties {
      title
      description
      cta {
        properties {
          link {
            url
            target
            name
          }
          ariaLabel
        }
      }
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 760, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      icon {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 361, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileBackground {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;
export default ThanksgivingBanner;
